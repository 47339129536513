import React, { useContext, useEffect } from "react";
import Translate from "react-translate-component";
import member1 from "../../../assets/images/faces/lenka_kucerova.png";
import img from "../../../assets/images/kontakt-obrazok.png";
import { StateContext } from "../../../context/state-context-provider";
import SectionHeadline from "../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../UI/SectionUnderText/SectionUnderText";
import callImg from "./../../../assets/images/call.svg";
import mailImg from "./../../../assets/images/mail.svg";
import imgBg from "./../../../assets/images/okrasa-srdce-otocene.svg";
import timeImg from "./../../../assets/images/time.svg";
import "./Contact.scss";

import CSModal from "../../UI/Modal/Modal";
import CheckboxContactForm from "../../checkbox-contact-form";
import ContactForm from "../../contact-form/";

const Contact = () => {
  const { cformOpen, setCFormOpen } = useContext(StateContext);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "normal"
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <section className="Contact">
      <div className="container">
        <div className="row">
          <div className="order-2 col-sm-12 order-md-1 col-md-6">
            <div className="cs_contact_info">
              <SectionHeadline className="dark left mt-5">
                <Translate
                  // className="mt-5"
                  content="contact.headline"
                />
              </SectionHeadline>
              <SectionHeadline className="dark small left mt-5">
                <Translate className="mt-5" content="contact.caresee" />
              </SectionHeadline>
              <Translate
                className="mb-0"
                content="contact.address"
                component="p"
              />
              <Translate
                className="mb-0"
                content="contact.city"
                component="p"
              />
              <Translate
                className="mb-0"
                content="contact.id"
                component="p"
                unsafe
              />
              <Translate
                className="mb-0"
                content="contact.tax_id"
                component="p"
                unsafe
              />{" "}
              <br />
              <Translate
                className="mb-0"
                content="contact.account"
                component="p"
                unsafe
              />
              <Translate
                className="mb-0"
                content="contact.bic"
                component="p"
                unsafe
              />
              <br />
              <Translate
                className="mb-0"
                content="contact.register"
                component="p"
              />
            </div>
            <div className="mt-5">
              <SectionHeadline className="dark x_small left mt-5">
                <Translate className="mt-5" content="contact.contact" />
              </SectionHeadline>
              <div className="cs_contact-info">
                <img src={mailImg} alt="mail" />
                <SectionUnderText className="dark x_small no-padding ml-3">
                  <Translate content="contact.email" />
                </SectionUnderText>
              </div>
              <div className="cs_contact-info">
                <img src={callImg} alt="call" />
                <SectionUnderText className="dark x_small no-padding ml-3">
                  <Translate content="contact.number" />
                </SectionUnderText>
              </div>
              <div className="cs_contact-info">
                <img src={callImg} alt="call" />
                <SectionUnderText className="dark x_small no-padding ml-3">
                  <Translate content="contact.number_two" />
                </SectionUnderText>
              </div>
              <div className="cs_contact-info">
                <img src={timeImg} alt="time" />
                <SectionUnderText className="dark no-padding ml-3">
                  <Translate content="contact.open_hours" />
                </SectionUnderText>
              </div>
            </div>
          </div>

          <div className="order-1 col-sm-12 order-md-2 col-md-6">
            <div className="cs_contact">
              <img className="bg-img" src={imgBg} alt="contact-img" />
              <img src={img} alt="contact-img" />
            </div>
          </div>
        </div>

        <div className="cs_profile-boxes d-flex">
          <div className="profile-box col-sm-12 col-md-6">
            <img src={member1} alt="member" className="portrait" />
            <div>
              <SectionUnderText className="dark bold left no-padding">
                <Translate content="contact.name_1" />
              </SectionUnderText>
              <SectionUnderText className="dark small left no-padding mb-3">
                <Translate content="contact.title_1" />
              </SectionUnderText>
              <div className="contact-info mb-2">
                <img src={mailImg} alt="mail" className="icon" />
                <SectionUnderText className="dark small no-padding">
                  <Translate content="contact.email_1" />
                </SectionUnderText>
              </div>
              <div className="contact-info">
                <img src={callImg} alt="call" className="icon" />
                <SectionUnderText className="dark small no-padding">
                  <Translate content="contact.number_1" />
                </SectionUnderText>
              </div>
            </div>
          </div>
          {/* <div className="profile-box col-sm-12 col-md-6">
            <img src={member_2} alt="member" className="portrait" />
            <div>
              <SectionUnderText className="dark bold left no-padding">
                <Translate content="contact.name_2" />
              </SectionUnderText>
              <SectionUnderText className="dark small left no-padding mb-3">
                <Translate content="contact.title_2" />
              </SectionUnderText>
              <div className="contact-info mb-2">
                <img src={mailImg} alt="mail" className="icon" />
                <SectionUnderText className="dark small no-padding">
                  <Translate content="contact.email_2" />
                </SectionUnderText>
              </div>
              <div className="contact-info">
                <img src={callImg} alt="call" className="icon" />
                <SectionUnderText className="dark small no-padding">
                  <Translate content="contact.number_2" />
                </SectionUnderText
              </div>
            </div>
          </div> */}
        </div>
        <div className="row">
          {/* Potom  z divu pod tymto commentom dat prec mb-5 a pb-5*/}
          <div className="col-md-12 mt-5 mb-5">
            <ContactForm />

            {cformOpen ? (
              <CSModal
                show={cformOpen}
                onHide={() => setCFormOpen(false)}
                className="checkbox-modal"
              >
                <CheckboxContactForm />
              </CSModal>
            ) : null}
          </div>
        </div>
      </div>
      {/* <div className="mapped-wrapper mt-5">
        <div className="image-wrapper">
          <img src={budova} alt="budova" />
        </div>
        <Map
          isMarkerShown
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `550px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div> */}
    </section>
  );
};

export default Contact;
