import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Translate from "react-translate-component";
import new_user from "../../../../../assets/images/new_user.png";
import people from "../../../../../assets/images/partners/01people.png";
import people_o from "../../../../../assets/images/partners/01people_o.png";
import hilbi_o from "../../../../../assets/images/partners/hilbi-logo-hover.svg";
import hilbi from "../../../../../assets/images/partners/hilbi-logo.svg";
import hipokrat from "../../../../../assets/images/partners/hipokrat.png";
import hipokrat_o from "../../../../../assets/images/partners/hipokrat_o.png";
import Button from "../../../../UI/Button/Button";
import CSModal from "../../../../UI/Modal/Modal";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import "./Partners.scss";
import PartnersItem from "./PartnersItem";

class Partners extends React.Component {
  state = {
    modalShow: false
  };

  modalClose = () => this.setState({ modalShow: false });

  render() {
    return (
      <section id={this.props.scrollAnchor} className="Partners">
        <CSModal show={this.state.modalShow} onHide={this.modalClose}>
          <img src={new_user} className="cs_modal_hireimg" alt="new user" />

          <Translate
            component="h4"
            className="cs_modal_headline"
            content="homepage.partners.hire_modal_headline"
          />

          <Translate
            component="p"
            className="cs_modal_txt"
            content="homepage.partners.hire_modal_txt"
            unsafe={true}
          />
        </CSModal>

        {/* <img className="cs_wave" src={wave} alt="wave" /> */}
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionHeadline className="dark mt-5">
                  <Translate content="homepage.partners.headline" />
                </SectionHeadline>
                <SectionUnderText className="dark">
                  <Translate content="homepage.partners.text" />
                </SectionUnderText>
              </ScrollAnimation>
            </div>

            <PartnersItem
              img={people}
              hovered={people_o}
              link="https://01people.com/"
            />
            {/* <PartnersItem
              img={tabita}
              hovered={tabita_o}
              link="http://www.tabitasro.sk/"
              delay={100}
            /> */}
            <PartnersItem
              img={hipokrat}
              hovered={hipokrat_o}
              link="http://www.hipokrat.sk/ "
              delay={200}
            />
            {/* <PartnersItem
              img={akademia}
              hovered={akademia_o}
              link="https://avvss.sk/"
              delay={300}
            /> */}
            <PartnersItem
              img={hilbi}
              hovered={hilbi_o}
              link="https://hilbi.com/"
              delay={400}
            />
            {/* <PartnersItem
              img={aopss}
              hovered={aopss_o}
              link="https://www.aopss.sk/ "
              delay={500}
            /> */}

            <div className="col-12 text-center mt-5 ">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <SectionUnderText className="dark">
                  <Translate content="homepage.partners.text_hire" />
                </SectionUnderText>
                <Button
                  className="black is-bigger is-wider mt-4"
                  onClick={() => this.setState({ modalShow: true })}
                >
                  <Translate content="homepage.partners.hire_btn" />
                </Button>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Partners;
