import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Translate from "react-translate-component";
import dev_team_3 from "../../../../../assets/images/faces/200303_Boris_Nanista_01@2x.png";
import caresee_team_1 from "../../../../../assets/images/faces/lenka_kucerova.png";
import dev_team_1 from "../../../../../assets/images/faces/Julia_Spevakova.jpeg";
import caresee_team_2 from "../../../../../assets/images/faces/Roman_Kucera.png";
import dev_team_2 from "../../../../../assets/images/faces/Vladimir_Osvald.jpg";
import dev_team_4 from "../../../../../assets/images/faces/nikola_muckova.jpg";
import SectionHeadline from "../../../../UI/SectionHeadline/SectionHeadline";
import SectionUnderText from "../../../../UI/SectionUnderText/SectionUnderText";
import wave from "./../../../../../assets/images/transition-orange.svg";
import "./Team.scss";
import TeamItem from "./TeamItem/TeamItem";

const Team = () => {
  return (
    <section className="Team">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 mt-5">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="dark">
                <Translate content="homepage.about.team.headline_care" />
              </SectionHeadline>
              <SectionUnderText className="dark full-width">
                <Translate content="homepage.about.team.text_care" unsafe />
              </SectionUnderText>
            </ScrollAnimation>
          </div>
          <TeamItem
            headline={<Translate content="homepage.about.team.member_2" />}
            text={<Translate content="homepage.about.team.member_2_pos" />}
            linked="https://www.linkedin.com/in/roman-kucera/"
            img={caresee_team_2}
            delay={100}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.member_1" />}
            text={<Translate content="homepage.about.team.member_1_pos" />}
            // linked="https://www.linkedin.com/in/andrea-%C5%A1vr%C4%8Dkov%C3%A1-285073121/"
            img={caresee_team_1}
          />
          {/* <TeamItem
						headline={<Translate content="homepage.about.team.member_3" />}
						text={<Translate content="homepage.about.team.member_3_pos" />}
						linked="https://www.linkedin.com/in/dominika-chalupov%C3%A1-71934318b"
						img={caresee_team_3}
						delay={200}
					/> */}
          {/* <TeamItem
						headline={<Translate content="homepage.about.team.member_4" />}
						text={<Translate content="homepage.about.team.member_4_pos" />}
						linked="https://www.linkedin.com/in/lenka-bilovsk%C3%A1-74a11b267"
						img={caresee_team_4}
						delay={300}
					/> */}

          <div className="col-12 mt-5">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
              <SectionHeadline className="dark">
                <Translate content="homepage.about.team.headline_dev" />
              </SectionHeadline>
              <SectionUnderText className="dark full-width">
                <Translate content="homepage.about.team.text_dev" unsafe />
              </SectionUnderText>
            </ScrollAnimation>
          </div>
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_1" />}
            text={<Translate content="homepage.about.team.dev_1_pos" />}
            linked="https://www.linkedin.com/in/julia-spevakova-68243548/"
            img={dev_team_1}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_2" />}
            text={<Translate content="homepage.about.team.dev_2_pos" />}
            img={dev_team_2}
            delay={100}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_3" />}
            text={<Translate content="homepage.about.team.dev_3_pos" />}
            linked="https://www.linkedin.com/in/boris-nani%C5%A1ta-51aa321b0/"
            img={dev_team_3}
            delay={200}
          />
          <TeamItem
            headline={<Translate content="homepage.about.team.dev_4" />}
            text={<Translate content="homepage.about.team.dev_4_pos" />}
            // linked="https://www.linkedin.com/in/adam-vician-b04388129/"
            img={dev_team_4}
            delay={300}
          />
        </div>
      </div>
      <img src={wave} alt="wave-about" className="cs_wave" />
    </section>
  );
};

export default Team;
